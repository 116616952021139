<template>
  <div class="workLog-report">
    <!-- 导航提示标题 -->
    <ml-tip :showHeader="false" content="日志填报" />
    <!-- 搜索框 -->
    <ml-tip :showHeader="false" style="border: 0" :bodyStyle="{}">
      <div class="workLog-header">
        <ml-form inline labelWidth="100px" :model="searchData" ref="searchDataRef">
          <ml-select
            prop="companyId"
            placeholder="请选择阿米巴"
            :options="unitOptions"
            label="阿米巴:"
            keyName="companyId"
            labelName="companyName"
            valueName="companyId"
            v-model="searchData.companyId"
            style="margin-bottom: 0"
            :disabled="disabledCompany"
          />
          <!-- 所属项目 -->
          <ml-select
            prop="projectId"
            placeholder="请选所属项目"
            :options="projectData"
            label="所属项目:"
            keyName="projectId"
            labelName="projectName"
            valueName="projectId"
            v-model="searchData.projectId"
            :style="{ marginBottom: 0, marginRight: '40px' }"
            :disabled="disabledProject"
          />
          <ml-select
            prop="userId"
            placeholder="请选择人员名称"
            :options="userOptions"
            label="人员名称:"
            keyName="userId"
            labelName="userName"
            valueName="userId"
            :filterable="true"
            v-model="searchData.userId"
            :style="{ marginBottom: 0, marginRight: '40px' }"
            :disabled="disabledProject"
          />
        </ml-form>

        <!-- 添加账号 -->
        <el-button type="primary" @click="importHandle">导出日志</el-button>
      </div>
    </ml-tip>
    <ml-tip
      content=""
      :showHeader="false"
      class="default-tip"
      :bodyStyle="{}"
      :style="`margin-bottom: 0; border: 0; min-height: ${tabberHeight(175)}px`"
    >
      <div class="duty-box" :style="{ height: `${tabberHeight(206)}px` }">
        <div class="duty-box-left">
          <div style="padding: 0px 50px 0 40px">
            <!-- 日历 -->
            <div id="zxVueCalendar" style="width: 400px"></div>
          </div>
        </div>
        <div class="duty-box-right">
          <ml-tip :showHeader="false" :bodyStyle="{ padding: '10px 0px 10px 20px' }">
            <div class="duty-box-right-tip">
              <div class="duty-box-right-tip-title">
                日志填报
                {{ workLogForm.workDate }}
              </div>

              <div>
                <el-button type="primary" @click="onSave" :disabled="formDisabled"
                  >保存日志</el-button
                >
              </div>
            </div>
          </ml-tip>

          <div class="duty-content">
            <el-radio-group v-model="workLogForm.fillType" :disabled="formDisabled" style="margin-bottom: 16px">
              <el-radio label="0">内勤</el-radio>
              <el-radio label="1">外勤</el-radio>
            </el-radio-group>
            <el-input
              v-model="workLogForm.workContent"
              :rows="5"
              type="textarea"
              placeholder="请输入日志内容"
              :readonly="formDisabled"
            />
            <div style="margin-top: 16px">
              <ml-upload
                autoUpload
                :action="action"
                list-type="picture-card"
                :limit="6"
                :data="{ businessType: 'workInfo' }"
                :headers="{ Authorization: toKen }"
                tip="请上传最优大小10M以内的图片！"
                name="file"
                :fileLists="workLogForm.fileList"
                :disabled="formDisabled"
                @on-success="onSuccess"
                @on-remove="removeHandle"
                :maxSize="10"
              >
              </ml-upload>
            </div>
          </div>
        </div>
      </div>
    </ml-tip>
    <ml-dialog
      width="600px"
      ref="exportLogDialogRef"
      title="导出日志"
      :btnLoading="btnLoading"
      @click-submit="submitExportLogHandle"
    >
      <template #body>
        <ml-form
          style="width: 100%; padding: 0 20px"
          labelWidth="100px"
          :model="exportLogData"
          ref="exportLogFormRef"
          :rules="exportLogFormRules"
        >
          <ml-select
            prop="companyId"
            placeholder="请选择阿米巴"
            :options="unitOptions"
            label="阿米巴:"
            keyName="companyId"
            labelName="companyName"
            valueName="companyId"
            v-model="exportLogData.companyId"
            :disabled="disabledCompany"
            clearable
          />
          <!-- 所属项目 -->
          <ml-select
            prop="projectId"
            placeholder="请选所属项目"
            :options="export_projectData"
            label="所属项目:"
            keyName="projectId"
            labelName="projectName"
            valueName="projectId"
            v-model="exportLogData.projectId"
            :disabled="disabledProject"
            clearable
          />
          <ml-select
            prop="userId"
            placeholder="请选择人员名称"
            :options="export_userOptions"
            label="人员名称:"
            keyName="userId"
            labelName="userName"
            valueName="userId"
            :filterable="true"
            clearable
            v-model="exportLogData.userId"
            :disabled="disabledProject"
            multiple
          />
          <!-- 日期 -->
          <ml-date-picker
            prop="monthList"
            placeholder="选择月份"
            label="导出月份:"
            type="month"
            value-format="YYYY-MM"
            v-model="exportLogData.monthList"
          />
        </ml-form>
      </template>
    </ml-dialog>
  </div>
</template>

<script>
import { computed, onMounted, reactive, ref, watch, onBeforeUnmount } from 'vue'
import { tabberHeight, getTabberData, downloadFile } from '@/utils'
import { exportWorkLogRequest } from '@/API/previewApi'
import { useStore } from 'vuex'
import ZxVueCalendar from 'zx-calendar'
import { BASEURL, UPLOAD } from '@API'

export default {
  name: 'workLogReport',
  setup() {
    const { commit, getters, dispatch } = useStore()

    const isBusinessDepartment = computed(() => getters.isBusinessDepartment)

    const getUserType = computed(() => getters.getUserType)

    const companyId = computed(() => getters.companyId)

    const userId = computed(() => getters.getUserId)

    const toKen = computed(() => getters.getToken)
    const disabledCompany = ref(false)
    const disabledProject = ref(false)

    const formatDate = value => {
      return value > 9 ? value : '0' + value
    }
    const nDate = new Date()
    const year = ref(nDate.getFullYear())
    const month = ref(formatDate(nDate.getMonth() + 1))
    const day = ref(formatDate(nDate.getDate()))

    // 处理当前时间，年月日

    /* 开始 搜索 **********************************/
    // 搜索条件
    const searchDataRef = ref()
    let searchData = reactive({
      userId: '',
      companyId: '',
      projectId: ''
    })

    const zxVueCalendar = ref()
    // 单位配置
    const unitOptions = ref([])

    // 所属项目配置
    const projectData = ref([])

    // 用户列表
    const userOptions = ref([])

    // 日志记录
    const workLogList = ref([])

    let workLogForm = reactive({
      id: '',
      workDate: '',
      workContent: '',
      fillType: "0",
      fileList: []
    })

    const isHhliday = ref([])

    // 监听
    watch(
      () => searchData.userId,
      newvalue => {
        if (newvalue) {
          getListHandle()
        } else {
          workLogForm.id = ''
          workLogForm.workDate = ''
          workLogForm.workContent = ''
          workLogForm.fileList = []
          workLogForm.fillType = '0'
          isHhliday.value = []
          workLogList.value = []
          initHandle()
        }
      }
    )
    const formDisabled = computed(() => {
      return (
        !workLogForm.workDate ||
        !searchData.userId ||
        searchData.userId !== userId.value ||
        verifyDay()
      )
    })

    const verifyDay = () => {
      if (!workLogForm.workDate) return true
      let workLogTime = new Date(workLogForm.workDate + ' 23:59:59').getTime()
      return nDate.getTime() - workLogTime >= 2 * 24 * 60 * 60 * 1000
    }

    const initHandle = () => {
      workLogForm.workDate = workLogForm.workDate || `${year.value}-${month.value}-${day.value}`
      if (zxVueCalendar.value) {
        zxVueCalendar.value.destroy()
      }
      const ZxVueCalendarOption = {
        el: '#zxVueCalendar',
        showHoliday: true,
        // 格式化日历项
        itemFormatter: e => {
          if (e.fullText) {
            //查看日历项是否是激活日期
            const fullText = e.fullText.replaceAll('/', '-')
            const fullTime = new Date(fullText + ' 00:00:00')
            e.disabled = fullTime.getTime() > nDate.getTime()
            // 如果日期正常并存在值班就显示点
            e.holiday = isHhliday.value.includes(fullText)
          }
          return e
        }
      }
      ZxVueCalendarOption.defaultDate = workLogForm.workDate
      zxVueCalendar.value = new ZxVueCalendar(ZxVueCalendarOption)
      if (zxVueCalendar.value) {
        zxVueCalendar.value.on('change', e => {
          const [y, M, d] = e[0].fullText.split('/')
          workLogForm.workDate = `${y}-${M}-${d}`
          getDetailData()
        })
      }
    }

    const getListHandle = async () => {
      if (!searchData.userId) return
      let params = {
        userId: searchData.userId,
        monthList: []
      }
      const { data = [] } = await dispatch('fetchGetWorkLogList', params)
      workLogList.value = data || []
      isHhliday.value = []
      if (workLogList.value.length > 0) {
        workLogList.value.map(item => {
          isHhliday.value.push(item.workDate)
        })
      }
      workLogForm.workDate = workLogForm.workDate || `${year.value}-${month.value}-${day.value}`
      getDetailData()
      initHandle()
    }

    const getDetailData = () => {
      const item = workLogList.value.find(d => d.workDate === workLogForm.workDate)
      if (item) {
        dispatch('fetchGetWorkLogById', { id: item.id }).then(data => {
          if (data.code === 200) {
            let formData = data.data
            workLogForm.id = formData.id
            workLogForm.workDate = formData.workDate
            workLogForm.workContent = formData.workContent
            workLogForm.fillType = formData.fillType
            workLogForm.fileList = formData.fileList.map(d => {
              return {
                ...d,
                url: d.filePath,
                name: d.fileName
              }
            })
          } else {
            workLogForm.id = ''
            workLogForm.workContent = ''
            workLogForm.fileList = []
            workLogForm.fillType = '0'
          }
        })
      } else {
        workLogForm.id = ''
        workLogForm.workContent = ''
        workLogForm.fillType = '0'
        workLogForm.fileList = []
      }
    }

    // 处理所属项目
    const getProjectData = async type => {
      const projectParams = {}
      if (isBusinessDepartment.value) {
        projectParams.companyId = type === 2 ? exportLogData.companyId : searchData.companyId
      }
      const { data } = await getTabberData(dispatch, 'fetchGetProjectsByCompany', projectParams)
      if (type === 1) {
        projectData.value = data || []
      } else if (type === 2) {
        export_projectData.value = data || []
      } else {
        projectData.value = data || []
        export_projectData.value = data || []
      }
    }
    // 监听
    watch(
      () => searchData.companyId,
      newvalue => {
        projectData.value = []
        userOptions.value = []
        searchData.projectId = ''
        searchData.userId = ''
        getProjectData(1)
        getUserListData(2)
      }
    )
    // 监听
    watch(
      () => searchData.projectId,
      newvalue => {
        userOptions.value = []
        searchData.userId = ''
        if (newvalue) {
          getUserListData(2)
        }
      }
    )

    // 获取用户列表
    const getUserListData = type => {
      let data = {
        userId: '',
        projectId: type === 3 ? exportLogData.projectId : searchData.projectId,
        companyId: type === 3 ? exportLogData.companyId : searchData.companyId,
        userName: ''
      }
      dispatch('fetchGetSelectUserByParam', data).then(data => {
        if (data && data.code === 200) {
          if (type === 1) {
            // 进页面时，初始
            userOptions.value = data.data || []
            export_userOptions.value = data.data || []
            searchData.userId = searchData.userId || userId.value
            exportLogData.userId =
              exportLogData.userId.length > 0 ? exportLogData.userId : [userId.value]
          } else if (type === 2) {
            // 搜素的项目
            userOptions.value = data.data || []
          } else if (type === 3) {
            // 导出日志弹窗的项目
            export_userOptions.value = data.data || []
          }
        }
      })
    }

    // 上传附件
    const imgList = ref([])
    const action = ref(`${BASEURL}${UPLOAD}`)
    const onSuccess = files => {
      if (files.data) {
        workLogForm.fileList.push({
          fileId: files.data.fileId,
          fileName: files.data.fileName,
          fileType: files.data.fileType,
          filePath: files.data.filePath,
          fileSize: files.data.fileSize,
          businessType: 'workInfo',
          url: files.data.filePath,
          name: files.data.fileName
        })
      }
    }

    const removeHandle = files => {
      const index = workLogForm.fileList.findIndex(d => d.fileId === files.data.fileId)
      if (index > -1) {
        workLogForm.fileList.splice(index, 1)
      }
    }

    const onSave = () => {
      if (!workLogForm.workDate) {
        commit('setError', {
          status: true,
          title: '',
          message: '请选择日期',
          type: 'waring'
        })
        return
      }
      if (!workLogForm.workContent) {
        commit('setError', {
          status: true,
          title: '',
          message: '请输入日志内容',
          type: 'waring'
        })
        return
      }
      dispatch('fetchSaveOrUpdateWorkLog', workLogForm).then(data => {
        if (data.code === 200) {
          commit('setError', {
            status: true,
            title: '',
            message: '保存成功',
            type: 'success'
          })
          getListHandle()
        }
      })
    }

    /* 开始 导出日志 **********************************/
    let btnLoading = ref(false)
    const exportLogDialogRef = ref()
    const exportLogFormRef = ref()
    const exportLogData = reactive({
      userId: [],
      projectId: '',
      companyId: '',
      monthList: ''
    })
    const exportLogFormRules = {
      monthList: [{ required: true, message: '请选择月份', trigger: 'blur' }]
    }
    // 导出表单的用户列表
    const export_userOptions = ref([])
    // 导出表单的所属项目配置
    const export_projectData = ref([])

    // 监听导出表单的阿米巴
    watch(
      () => exportLogData.companyId,
      newvalue => {
        export_userOptions.value = []
        export_projectData.value = []
        exportLogData.projectId = ''
        exportLogData.userId = []

        getProjectData(2)
        getUserListData(3)
      }
    )
    // 监听导出表单的项目
    watch(
      () => exportLogData.projectId,
      newvalue => {
        export_userOptions.value = []
        exportLogData.userId = []
        if (newvalue) {
          getUserListData(3)
        }
      }
    )

    const importHandle = () => {
      exportLogDialogRef.value.showDialog = true
    }
    const submitExportLogHandle = () => {
      exportLogFormRef.value.CustomFormRef.validate().then(() => {
        if (
          exportLogData.userId.length === 0 &&
          !exportLogData.projectId &&
          !exportLogData.companyId
        ) {
          commit('setError', {
            status: true,
            title: '',
            message: '人员和阿米巴，项目不能同时为空',
            type: 'warning'
          })
          return
        }
        btnLoading.value = true
        let nParams = {
          userId: exportLogData.userId.join(','),
          projectId: exportLogData.projectId,
          companyId: exportLogData.companyId,
          monthList: [exportLogData.monthList]
        }
        exportWorkLogRequest(nParams)
          .then(res => {
            btnLoading.value = false
            let fileName = exportLogData.monthList + '.xlsx'
            downloadFile(res.data, fileName)
            exportLogDialogRef.value.showDialog = false
            exportLogFormRef.value.CustomFormRef.resetFields()
          })
          .catch(error => {
            btnLoading.value = false
          })
      })
    }

    /* 结束 导出日志 **********************************/

    onMounted(async () => {
      // 获取单位名
      const { data } = await getTabberData(dispatch, 'fetchGetCompanyInfos', {
        noCompanyCode: 'business_department'
      })
      unitOptions.value = data || []
      getUserListData(1)
      if (getUserType.value === 'division_manager') {
        // 事业部负责人
        disabledCompany.value = false
        disabledProject.value = false
        getProjectData(3)
      } else if (getUserType.value === 'amiba_manager') {
        // 阿米巴负责人
        disabledCompany.value = true
        disabledProject.value = false
        searchData.companyId = companyId
        exportLogData.companyId = companyId
      } else {
        disabledCompany.value = true
        disabledProject.value = true
        searchData.companyId = companyId
        exportLogData.companyId = companyId
      }
      initHandle()
    })

    onBeforeUnmount(() => {
      if (zxVueCalendar.value) {
        zxVueCalendar.value.destroy()
      }
    })

    return {
      toKen,
      tabberHeight,
      searchDataRef,
      searchData,
      unitOptions,
      projectData,
      userOptions,
      getProjectData,
      getUserListData,
      workLogForm,
      imgList,
      action,
      onSuccess,
      workLogList,
      getUserType,
      getListHandle,
      onSave,
      disabledCompany,
      disabledProject,
      companyId,
      userId,
      year,
      month,
      day,
      getDetailData,
      formDisabled,
      verifyDay,
      importHandle,
      btnLoading,
      exportLogDialogRef,
      exportLogFormRef,
      exportLogData,
      exportLogFormRules,
      submitExportLogHandle,
      export_userOptions,
      export_projectData,
      formatDate,
      removeHandle
    }
  }
}
</script>

<style lang="scss" scoped>
.workLog-report {
  @extend %params-global;
  .el-input__inner {
    width: 170px;
  }
}

.duty-box {
  display: flex;
  height: 100%;
  &-left {
    width: 480px;
    border-right: 1px solid #d8d8d8;
  }
  &-right {
    flex: 1;
    &-tip {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &-title {
        font-weight: bold;
      }
    }
  }
}
.duty-content {
  padding: 16px;
}
</style>
<style lang="scss">
// 日历样式
.zx-calendar .zx-calendar-header-wrapper .__l button:before,
.zx-calendar .zx-calendar-header-wrapper .__l button:after,
.zx-calendar .zx-calendar-header-wrapper .__r button:before,
.zx-calendar .zx-calendar-header-wrapper .__r button:after {
  border-top: 1px solid #409eff;
  border-right: 1px solid #409eff;
}
.zx-calendar .zx-calendar-body-wrapper .__holiday {
  background: #409eff;
}
.zx-calendar .zx-calendar-body-wrapper .__item.is-current .__inner .__text {
  background: #fff;
  color: #409eff;
}
.zx-calendar .zx-calendar-body-wrapper .__item.is-selected .__inner .__text,
.zx-calendar .zx-calendar-body-wrapper .__item.is-range-temp .__inner .__text,
.zx-calendar .zx-calendar-body-wrapper .__item.is-range-first-last .__inner .__text {
  background: #409eff;
  color: #fff;
}
.workLog-header {
  display: flex;
  justify-content: space-between;
}
</style>